import { render, staticRenderFns } from "./products-business.vue?vue&type=template&id=4457e8c6&scoped=true&"
import script from "./products-business.vue?vue&type=script&lang=js&"
export * from "./products-business.vue?vue&type=script&lang=js&"
import style0 from "./products-business.vue?vue&type=style&index=0&id=4457e8c6&lang=stylus&scoped=true&"
import style1 from "./products-business.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4457e8c6",
  null
  
)

component.options.__file = "products-business.vue"
export default component.exports