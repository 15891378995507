<template>
  <div>
    <q-pull-to-refresh :handler="getProducts" refresh-icon="ion-sync" v-bind:release-message="$t('PTR.RELEASE')" v-bind:refresh-message="$t('PTR.REFRESH')" v-bind:pull-message="$t('PTR.PULL')" color="faded">
      <q-scroll-observable @scroll="scrolled"/>
      <q-page v-if="ready">
        <div v-if="!products.length" class="column items-center">
          <div class="text-center full-width" :style="(ecosystem_id === 'foods')?'':'padding-top:20vh'">
            <div>
              <template v-if="ecosystem_id === 'foods'">
                <q-card key="tg-tasks05" class="q-card-grouped text-center no-shadow no-border no-background flex-auto width-auto">
                  <q-card-main class="column justify-center full-height">
                    <div class="col-xs-auto">
                      <q-btn class="limit-width-1024 full-width text-family-brand text-weight-semibold uppercase" color="secondary-light" text-color="secondary" rounded label="Personalize"/>
                    </div>
                  </q-card-main>
                </q-card>
              </template>
              <img class="hero dark-img-hero" :src="`statics/ecosystems/${ecosystem_id}/icon.svg`">
            </div>
            <div>
              <p class="text-family-brand text-weight-regular">{{ $t(getEcosystemLabel('ADD_L')) }} <q-icon name="ion-add-circle" class="vertical-middle" style="margin-left: 4px"/></p>
            </div>
          </div>
        </div>
        <template v-else>
          <!-- show (business) products -->
          <div v-if="ecosystem.ecosystem_id === 'business'" class="items-center">

            <p class="text-center no-margin-bottom text-weight-semibold font-size-140p">Manage Channels</p>
            <p class="text-center caption uppercase text-weight-semibold text-shallow no-margin font-size-80p">
              Manage your business channels
            </p>
            <div class="flex flex-center full-height">
            <q-list no-border highlight link style="width: 100vw; max-width: 700px">
              <transition v-for="(product, index) of products" :key="`list-f-${index}`" appear enter-active-class="fadeInUp animated800">
                <q-item :key="`list-p-${index}`" :to="`/business/${product.uri}`">
                  <q-item-side>
                    <q-icon :name="`ion-${type2IconName(product.data.business.types[0])}`" color="secondary" size="30px" style="width:30px"/>
                  </q-item-side>
                  <q-item-side left style="
                  position: relative;
                  margin-right: -42px;
                  right: -14px;
                  top: -11px;
                  min-width: auto;
                  width: 25px;
                  height: 25px;
                  text-align: center;
                  z-index: 111;
                  ">
                    <q-item-tile v-if="product.verified">
                      <img src="/statics/_demo/checkmark.fill.seal_wing.svg" width="20" :alt="$t('VERIFIED_' + product.verified_status)" :title="$t('VERIFIED_' + product.verified_status)"
                      style="
                      position: relative;
                      top: 0;
                      left: 0;
                      border-radius: 1em;
                      padding: 2px;
                      width: 25px;
                      height: 25px;
                      border: 1px solid #fff;
                      background-color: #fff;
                      "/>
                    </q-item-tile>
                    <!--
                    <q-item-tile v-if="index === 0 || index > 3" icon="ion-checkmark-circle" color="green"/>
                    <q-item-tile v-if="index === 1" icon="ion-alert" color="attention"/>
                    <q-item-tile v-if="index === 2" icon="ion-close-circle" color="protect"/>
                    <q-item-tile v-if="index === 3" icon="ion-information-circle" color="shallow"/>
                    -->
                  </q-item-side>
                  <q-item-side>
                    <q-item-tile avatar>
                      <img v-if="getProductLogo(product) || getProductPhotoResource(product.data.media.photos.resources)" :src="getProductLogo(product) ? getProductLogo(product) : getProductPhotoResource(product.data.media.photos.resources)" :style="getProductLogo(product) ? getProductLogoStyle(product) : ''">
                      <img v-else style="background-color: #9B9B9B" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" class="no-photos-avatar"/>
                    </q-item-tile>
                  </q-item-side>
                  <!-- <q-item-main :label="product.data.business.name" :sublabel="product.data.business.address.vicinity"/> -->
                  <q-item-main>
                    <q-item-tile label>
                      {{ product.data.business.name }}
                    </q-item-tile>
                    <q-item-tile sublabel>
                      <template v-if="v = getVenue(product)">
                        <q-chip dense color="shallower" text-color="core" :avatar="v.logo">{{ v.name }}</q-chip>
                      </template>
                      <template v-else>{{ product.data.business.address.vicinity }}</template>
                    </q-item-tile>
                  </q-item-main>
                  <q-item-side right>
                    <q-item-tile icon="ion-arrow-forward" color="black" />
                  </q-item-side>
                </q-item>
              </transition>
            </q-list>
            </div>
            <!-- <div class="product column items-center"> -->
            <!-- <div class="product row justify-center items-baseline"> -->
            <div class="row justify-between items-center margin-auto-lr datagroup-container on-top-xl hidden">
              <template v-for="(product, index) of products">
                <transition :key="`${product.id}-trx`" appear enter-active-class="animated fadeInUp animated-d200">
                  <q-card style="margin-top: 22px" :key="product.id" inline class="q-card-grouped q-card-widget overflow-hidden" :data-index="index" :ref="`property-card-${product.id}`" v-touch-pan.noMouse="(obj) => { setCardIntent(obj, product.id, processPropertySelection) }">
                    <q-card-media style="
                    max-height: 222px;
                    min-height: 222px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center top;
                    " :style="showImages ? `background-image: url(${getProductPhotoResource(product.data.media.photos.resources)})` : 'min-height: 0; max-height: 0; height: 0;'">
                      <img v-if="!getProductPhotoResource(product.data.media.photos.resources)" style="height: 222px; background-color: #9B9B9B" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" class="no-photos">
                    </q-card-media>
                    <q-card-title class="line-height-sm">
                      {{ product.data.business.name }}
                      <span slot="subtitle" class="text-family-brand text-weight-medium block">
                        {{ product.data.business.address.full }}
                      </span>
                      <div slot="right" class="text-center" style="min-width: 40px">
                        <q-icon :name="`ion-${type2IconName(product.data.business.types[0])}`" color="secondary" size="40px"/>
                      </div>
                    </q-card-title>
                    <q-card-main class="hidden verified text-family-brand flex justify-center no-padding-top text-weight-semibold text-grey font-size-120p capitalize">
                      <span class="text-educate heartbeat" v-if="product.data.channel.online === 1">{{ $t('LIVE') }}</span>
                      <span v-else>{{ $t('OFFLINE.LABEL') }}</span>
                    </q-card-main>
                    <q-card-main class="hidden verified text-family-brand flex justify-center no-padding-top capitalize">
                      <div v-if="product.verified || product.verified_status != 'NONE'">
                        <q-icon v-show="!product.verified" :class="{ 'animate-spin': (product.verified_status != 'VERIFIED'), 'text-primary': product.verified, 'text-secondary': !product.verified }" size="22px" name="ion-flower"></q-icon>
                        <span style="margin-right:4px" :class="{ 'text-secondary': !product.verified, 'text-primary': product.verified }" class="text-weight-medium">{{ $t('VERIFIED_' + product.verified_status) }}</span>
                        <span v-if="product.verified_status === 'VERIFIED'" class="text-subinfo-l text-weight-regular">on {{ product.verified_timestamp | tformat }}</span>
                      </div>
                      <div v-else>
                        <span class="text-wing text-weight-semibold font-size-140p">
                          {{ $t('VERIFIED_NOT') }}
                        </span>
                      </div>
                    </q-card-main>
                    <q-card-separator v-show="!$q.platform.has.touch"/>
                    <q-card-actions v-show="!$q.platform.has.touch">
                      <q-btn class="full-width" color="primary" flat rounded :label="$t('VIEW')" @click="processPropertySelection(product.id)">
                        <q-tooltip :delay="2000" inverted>
                          {{ $t(getEcosystemLabel('VIEW.LABEL_TT')) }}
                        </q-tooltip>
                      </q-btn>
                      <!--
                      <q-btn color="protect" flat round right icon="ion-trash" @click="processPropertyDeletion(product.id)">
                        <q-tooltip :delay="2000" inverted>
                          {{ $t(getEcosystemLabel('DELETE.LABEL_TT')) }}
                        </q-tooltip>
                      </q-btn>
                      -->
                    </q-card-actions>
                  </q-card>
                </transition>
              </template>
            </div>
          </div>

        </template>
        <!-- <l-footer v-if="products.length" simple/> -->
      </q-page>
      <div v-else class="flex flex-center" style="height: 100vh">
        <q-spinner-puff size="200px" class="loading-spinner loading-spinner-gold" style="color: #F4A724; display: block;"/>
      </div>
    </q-pull-to-refresh>
    <!--
      STICKY_BUTON: Add Product
    -->
    <q-page-sticky position="bottom-right" :offset="[20, 20]">
      <transition appear enter-active-class="animated fadeInUp animated400" leave-active-class="animated fadeOutDown animated400">
        <q-btn v-if="dialogMyProductsAddShowButton" size="lg" v-ripple round push color="primary" @click="createChannel" class="shadow-24">
          <q-icon name="ion-add" size="44px"></q-icon>
        </q-btn>
      </transition>
    </q-page-sticky>

    <!--
      MODAL: Add Channel
    -->
    <q-modal id="dialogChannelCreate" v-model="dialogChannelCreateShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>create channel</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogChannelCreateShow = false">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-item @click.native="createChannelBusiness">
              <q-item-side class="text-center">
                <q-icon name="ion-logo-google" size="24px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Google Business</q-item-tile>
                <q-item-tile sublabel>
                  Create a channel based on an existing Google business.
                </q-item-tile>
              </q-item-main>
            </q-item>
            <q-item @click.native="createChannelCollection">
              <q-item-side class="text-center">
                <q-icon name="ion-megaphone" size="24px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Event Channel</q-item-tile>
                <q-item-tile sublabel>Create a channel based on an event.</q-item-tile>
              </q-item-main>
            </q-item>
            <q-item @click.native="createChannelCollection">
              <q-item-side class="text-center">
                <q-icon name="ion-list" size="24px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Custom Channel</q-item-tile>
                <q-item-tile sublabel>Create a channel list of services.</q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Add Product
    -->
    <q-modal v-model="dialogMyProductsAdd.show" position="bottom" class="appLayer dialog-item" :no-esc-dismiss="dialogMyProductsAdd.busy || dialogMyProductsAdd.done" :no-backdrop-dismiss="dialogMyProductsAdd.busy || dialogMyProductsAdd.done">
      <q-modal-layout>
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t(getEcosystemLabel('ADD_YOUR_PRODUCT')) }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn :disabled="dialogMyProductsAdd.busy" class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogMyProductsAdd.show = false">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top" style-ignore="padding-top: 0.5rem">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <span :class="{ 'invisible': dialogMyProductsAdd.slide === 0 , 'z-top relative-position block animated fadeInUp': dialogMyProductsAdd.slide > 0 && dialogMyProductsAdd.slide < 4, 'z-top relative-position block animated fadeOutUp': dialogMyProductsAdd.slide === 4 }">
            <vue-autosuggest
              v-model="dialogMyProductsAdd.query"
              @selected="selectProduct"
              :get-suggestion-value="getSuggestionValue"
              :suggestions="dialogMyProductsAdd.address.suggestion"
              :input-props="{
                placeholder: $t(getEcosystemLabel('ENTER_YOUR_PRODUCT')),
                onInputChange: refine,
                class: 'input-autosuggest capitalize'
              }"
              class="ais-input-autocomplete"
              @input="refine"
              :disabled="dialogMyProductsAdd.busy"
            >
              <template slot-scope="{ suggestion }">
                <div class="row justify-between content-stretch overflow-hidden cursor-pointer">
                  <span class="full-width capitalize text-weight-semibold text-black" style="line-height: 1rem; margin-bottom: 6px; margin-left: 2px">
                    <span class="text-center float-left" style="width: 26px; height: 26px; margin-right: 8px; padding-bottom: 40px">
                      <q-icon :name="`ion-${type2IconName(suggestion.item.types[0])}`" color="secondary" size="24px"/>
                    </span>
                    {{ suggestion.item.structured_formatting.main_text }}
                    <span class="block text-tertiary text-weight-regular animated fadeInUp animated-d200" style="margin-top: 4px">
                      {{ suggestion.item.structured_formatting.secondary_text }}
                    </span>
                  </span>
                </div>
              </template>
            </vue-autosuggest>

          </span>
          <q-carousel v-model="dialogMyProductsAdd.slide" no-swipe height="48vh" :easing="dialogMyProductsAdd.overshoot" :animation="1000">
            <q-carousel-slide class="flex flex-center" key="dialogMyProductsAddSlide0">
              <div class="text-center">
                <div class="text-family-brand text-weight-bolder q-title">{{ $t(getEcosystemLabel('PREPARE_SPOT')) }}</div>
                <div style="margin-bottom: 20vh" class="text-family-brand q-subheading text-faded">{{ $t(getEcosystemLabel('PREPARE_SPOT_D')) }}</div>
                <div style="padding: 4px; height: 20vh;" class="flex flex-center" :class="{ 'animated fadeIn': dialogMyProductsAdd.slide === 0 }">
                  <q-spinner-puff size="80px" color="secondary"/>
                </div>
              </div>
            </q-carousel-slide>
            <q-carousel-slide class="flex flex-center" key="dialogMyProductsAddSlide1">
              <div class="text-center">
                <div class="text-family-brand text-weight-bolder q-title">{{ $t(getEcosystemLabel('PRODUCT_LOCATION')) }}</div>
                <div style="margin-bottom: 20vh" class="text-family-brand q-subheading text-faded">{{ $t(getEcosystemLabel('PRODUCT_LOCATION_D')) }}</div>
                <img style="padding: 4px; height: 10vh;" src="/statics/_demo/mappin.svg" class="dark-img-invert-70" :class="{ 'animated fadeIn': dialogMyProductsAdd.slide === 1 }">
              </div>
            </q-carousel-slide>
            <q-carousel-slide class="flex flex-center" key="dialogMyProductsAddSlide2">
              <div class="text-center">
                <div class="text-family-brand text-weight-bolder q-title">{{ $t(getEcosystemLabel('SEARCHING')) }}</div>
                <div style="margin-bottom: 20vh" class="text-family-brand q-subheading text-faded">{{ $t(getEcosystemLabel('SEARCHING_D')) }}</div>
                <img style="padding: 4px; height: 10vh;" src="/statics/_demo/magnifyingglass.svg" class="dark-img-invert-70" :class="{ 'animated fadeIn': dialogMyProductsAdd.slide === 2 }">
              </div>
            </q-carousel-slide>
            <q-carousel-slide class="flex flex-center" key="dialogMyProductsAddSlide3">
              <div class="text-center">
                <div class="text-family-brand text-weight-bolder q-title">{{ $t(getEcosystemLabel('FOUND_PRODUCT')) }}</div>
                <div style="margin-bottom: 20vh" class="text-family-brand q-subheading text-faded">{{ $t(getEcosystemLabel('FOUND_PRODUCT_D')) }}</div>
                <img style="padding: 4px; height: 10vh;" src="/statics/_demo/signal_primary.svg" class="dark-img-invert-70" :class="{ 'animated fadeIn': dialogMyProductsAdd.slide === 3 }">
              </div>
            </q-carousel-slide>
            <q-carousel-slide class="flex flex-center" key="dialogMyProductsAddSlide4">
              <div class="text-center">
                <div class="text-family-brand text-weight-bolder q-title">{{ $t(getEcosystemLabel('PRODUCT_READY')) }}</div>
                <div style="margin-bottom: 20vh" class="text-family-brand q-subheading text-faded">{{ $t(getEcosystemLabel('PRODUCT_READY_D')) }}</div>
                <img style="padding: 4px; height: 10vh;" src="/statics/_demo/checkmark_green.svg" class="dark-img-invert-70" :class="{ 'animated fadeInRight': dialogMyProductsAdd.slide === 4 }">
              </div>
            </q-carousel-slide>
            <q-carousel-control slot="control-progress" slot-scope="carousel" position="bottom">
              <q-progress :percentage="carousel.percentage" color="secondary" stripe animate height="12px"
                class="absolute animated" :class="{ 'invisible': dialogMyProductsAdd.slide === 0, 'fadeInUp': dialogMyProductsAdd.slide === 1, 'fadeOut': dialogMyProductsAdd.slide === 4 }"
                style="top: 0px"
              />
            </q-carousel-control>
          </q-carousel>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Removing Home
    -->
    <q-modal v-model="dialogRemovingShow" minimized no-esc-dismiss no-backdrop-dismiss>
      <q-modal-layout>
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t(getEcosystemLabel('DELETE.DELETING')) }}</q-toolbar-title>
        </q-toolbar>
        <div class="layout-padding">
          <q-progress indeterminate color="secondary" stripe animate height="12px"/>
        </div>
      </q-modal-layout>
    </q-modal>
  </div>
</template>

<script>
// fw modules
import { easing, QSpinnerRings } from 'quasar'

// components + plugs + 3rd party
import { axiosLIO } from 'plugins/axios'
// algolia search
// import algoliasearch from 'algoliasearch/lite'
// import 'instantsearch.css/themes/algolia-min.css'
import { VueAutosuggest } from 'vue-autosuggest'

// formatting modules
import moment from 'moment'
import nformat from 'vue-filter-number-format'

// letsb services
import letsb from '../services/letsb.js'

// Wings 2.0
import Wings from '../services/wings-2.js'

export default {
  name: 'PageProducts',
  props: ['auth', 'authenticated', 'lang', 'ecosystem', 'anyDarkmode', 'modalAdapt'],
  components: {
    VueAutosuggest
  },
  filters: {
    nformat: nformat,
    tformat: function (val) {
      return moment(val).format('MMMM Do, YYYY')
    }
  },
  beforeMount () {
    // check if we have data already
    let forceReload = true
    let storedProducts = this.$store.state.app.products
    // if stored, load from cache
    if (!forceReload && storedProducts.list.length) {
      this.products = storedProducts.list
      this.productsGroup = storedProducts.group
      this.ready = true
    } else {
      this.getProducts(() => {
        this.ready = true
      })
    }
  },
  mounted () {
    document.querySelector('#appHeader').classList.add('no-shadow')
    document.querySelector('#appHeader').classList.add('no-border')
    document.querySelector('#appHeader').classList.remove('animate-hide')
    this.$emit('stopAjaxBar')
    setTimeout(() => {
      this.dialogMyProductsAddShowButton = true
    }, 900)
  },
  created () {
    setTimeout(() => {
      document.querySelector('.q-layout-page-container').style.paddingTop = '100px'
    }, 400)
  },
  computed: {
    ecosystem_id () {
      return this.ecosystem.ecosystem_id
    },
    ecosystem_id_t () {
      try {
        return this.ecosystem.ecosystem_id.toUpperCase()
      } catch (error) {
        return 'BUSINESS'
      }
    }
  },
  watch: {
    lang: {
      handler: (val) => {
        moment.locale({
          'enUS': 'en',
          'zhCN': 'zh-cn',
          'esUS': 'es-us'
        }[val])
      }
    }
  },
  data () {
    return {
      ready: false,
      dialogRemovingShow: false,
      dialogMyProductsAddShowButton: false,
      dialogChannelCreateShow: false,
      dialogMyProductsAdd: {
        show: false,
        busy: false,
        done: false,
        slide: 0,
        overshoot: easing.overshoot,
        address: {
          autocomplete: null,
          suggestion: []
        },
        query: '',
        engine: null, // algoliasearch('TVMBTFA3WS', '3f46c9779c1190490afbbcca337efaed')
        timeout: null,
        product: null,
        debounceMilliseconds: 250
      },
      products: [],
      productsGroup: {},
      device: {
        pos: null
      },
      showImages: true
    }
  },
  methods: {
    scrolled (scroll) {
      if (scroll.direction === 'down' && scroll.position >= 1) {
        document.querySelector('#appHeader').classList.remove('no-shadow')
      } else if (scroll.direction === 'up' && scroll.position <= 10) {
        document.querySelector('#appHeader').classList.add('no-shadow')
      }
    },
    getVenue (product) {
      try {
        let placeId = product.data.place_id
        let venueId = Wings.venues.relations[placeId]
        if (venueId) {
          this.venue = Wings.venues.list[venueId]
          return this.venue
        }
      } catch (e) { }
      return false
    },
    type2IconName (type) {
      const type2IconTable = {
        'restaurant': 'restaurant',
        'food': 'restaurant',
        'meal_takeaway': 'restaurant',
        'meal_delivery': 'restaurant',
        'cafe': 'cafe',
        'laundry': 'shirt',
        'store': 'basket',
        'shopping_mall': 'cart',
        'supermarket': 'cart',
        'book_store': 'book',
        'bar': 'beer',
        'stadium': 'football',
        'hospital': 'medical',
        'veterinary_care': 'paw',
        'pet_store': 'paw',
        'electronics_store': 'desktop',
        'movie_theater': 'film',
        'florist': 'rose',
        'gym': 'fitness',
        'school': 'school',
        'university': 'school',
        'airport': 'airplane',
        'hair_care': 'cut',
        'beauty_salon': 'cut',
        'art_gallery': 'easel',
        'car_repair': 'car',
        'car_dealer': 'car',
        'parking': 'car',
        'gas_station': 'car',
        'drugstore': 'medkit',
        'pharmacy': 'medkit',
        'police': 'medal',
        'bank': 'card',
        'atm': 'cash',
        'home_goods_store': 'pricetags',
        'hardware_store': 'construct',
        'place_of_worship': 'heart-half',
        'mosque': 'heart-half',
        'church': 'heart-half',
        'synagogue': 'heart-half'
      }
      return type2IconTable[type] || 'business'
    },
    refine () {
      let _ = this
      clearTimeout(this.dialogMyProductsAdd.timeout)
      this.dialogMyProductsAdd.timeout = setTimeout(() => {
        const params = ['q=' + _.dialogMyProductsAdd.query]
        if (_.device.pos && _.device.pos.coords) {
          params.push('loc=' + [_.device.pos.coords.latitude, _.device.pos.coords.longitude].join(','))
        }
        const queryPromise = axiosLIO.get('/products-suggestions?' + params.join('&'))

        Promise.all([queryPromise]).then(values => {
          _.dialogMyProductsAdd.address.suggestion = []

          let products = values[0].data.data.products
          _.dialogMyProductsAdd.address.suggestion.push({ name: 'businesses', data: products })
        })
      }, this.dialogMyProductsAdd.debounceMilliseconds)
    },
    getSuggestionValue (suggestion) {
      return suggestion.item.description
    },
    getProductPhotoResource (resources) {
      // sort by height
      // resources.sort((r1, r2) => {
      //   return r1.height - r2.height
      // })
      if (!resources || resources.length === 0) {
        return null
      }
      let resource = resources[0]
      // console.log(resources)
      // for (let i in resources) {
      //   let r = resources[i]
      //   let s = (r.height > 315) ? '⚠️' : '👍'
      //   console.log(`${s} ${r.width}×${r.height}`)
      // }
      if (resource.photo_reference) {
        return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=800&photoreference=${resource.photo_reference}&key=AIzaSyB3og1L9DJe2lM7q3HocbVdLM2Q6lDhPZY`
      } else if (resource.photo_source) {
        return resource.photo_source
      }
    },
    getProductLogo (product) {
      try {
        return product.data.media.logo.url
      } catch (e) {}
      // custom
      switch (product.data.business.name) {
        case 'Talia Apartments':
          return 'https://www.talia-apts.com/uploads/properties/logos/639x639G/17338/white-logo_talia.png?1602865205'
        case 'Zaroob Motor City':
          return 'https://eatopi-content.kitopiconnect.com/images/logos/3ed2d6db-b6bd-47bd-9607-a8b7fbc1c009/header/9xtonkii3qf'
        case 'Starbucks':
          return 'https://upload.wikimedia.org/wikipedia/en/thumb/d/d3/Starbucks_Corporation_Logo_2011.svg/1920px-Starbucks_Corporation_Logo_2011.svg.png'
        case 'Allo Beirut - Hessa Street':
          return 'https://cdn-gdhcp.nitrocdn.com/YdLvFIYNyGMsMgiMTKYStcgbqCdztZVD/assets/images/optimized/rev-d9c8d70/wp-content/uploads/2020/12/logo2.png'
        case 'Avalon Marlborough':
          return 'https://www.avaloncommunities.com/pf/resources/img/brand-logos/avalon.svg?d=79'
      }
      return false
    },
    getProductLogoStyle (product) {
      let logoStyle = {
        backgroundColor: 'white',
        borderRadius: '4px'
      }
      try {
        return {
          backgroundColor: product.data.media.logo.bg,
          borderRadius: (product.data.media.logo.shape === 'circle') ? '4px' : '0px'
        }
      } catch (e) {}
      switch (product.data.business.name) {
        case 'Talia Apartments':
        case 'Avalon Marlborough':
          logoStyle.backgroundColor = 'black'
          break
        case 'Allo Beirut - Hessa Street':
          logoStyle.borderColor = 'transparent'
          break
        case 'Starbucks':
          logoStyle.borderRadius = '44px'
          break
      }
      return logoStyle
    },
    selectProduct (selected) {
      if (selected) {
        this.dialogMyProductsAdd.product = selected
        // get information about this product
        this.processProduct()
      }
    },
    getEcosystemLabel (l) {
      return `E.${this.ecosystem_id_t}.${l}`
    },
    $EL (l) {
      return this.getEcosystemLabel(l)
    },
    utilGetRandomInt (min, max) {
      min = Math.ceil(min)
      return Math.floor(Math.random() * (Math.floor(max) - min + 1)) + min
    },
    getProductsIO () {
      return axiosLIO.get('/products')
    },
    getProducts (done) {
      this.$emit('startAjaxBar')
      const _getProducts = this.getProductsIO().then((response) => {
        this.$emit('stopAjaxBar')
        let _p = [], _pGroup = { owner: [], not_owner: [] }
        // walk through products
        let _products = response.data.data.products
        _products.forEach((item, index) => {
          // decode the payload
          item.data = JSON.parse(item.payload)
          // set progress
          item.data.progress = item.data.progress ? item.data.progress : 0
          // save entry
          _p[index] = item
          _pGroup[item.data.owner ? 'owner' : 'not_owner'].push(item)
          // log [item]
          console.log(item)
        })
        this.products = _p
        this.productsGroup = _pGroup
        // update store
        this.$store.commit('app/updateProducts', { list: _p, group: _pGroup })
        setTimeout(() => done(), 400)
      }).catch(error => {
        //
        // TODO: Factorize! Bad-error. Make Global
        //
        this.$emit('stopAjaxBar')
        console.error(':error', error)
        console.error(':io_access_token: ' + localStorage.getItem('io_access_token'))
        // end session
        this.$q.notify({
          message: this.$t('SESSION.ENDED.L'),
          color: 'white',
          textColor: 'value',
          detail: this.$t('SESSION.ENDED.D'),
          position: 'top',
          timeout: 3000
        })
        // logout
        this.$router.push('/logout')
        done()
      })
      return _getProducts
    },
    mutateProduct (id, payload, audit, done) {
      console.log('🦋 [product] mutate')
      axiosLIO.post('/product/mutate', {
        id,
        payload: JSON.stringify(payload),
        audit
      }).then((res) => {
        done(res)
      }).catch(err => {
        done(err)
      })
    },
    computeCompsHome (property) {
      letsb.e.homes.comps.compute(property)
    },
    processProduct () {
      let productId = this.dialogMyProductsAdd.product.item.reference
      if (!productId) {
        return
      }
      // remove focus from input
      try {
        document.querySelector('#autosuggest > input').blur()
      } catch (e) {
        console.log(e)
      }
      this.dialogMyProductsAdd.busy = true
      this.dialogMyProductsAdd.slide++
      console.log('seed product with reference', productId)
      // seed product
      axiosLIO.post('/product/seed', {
        id: productId
      }).then((res) => {
        if (res.data.status === 'ok') {
          var product = JSON.parse(res.data.data)
          // update payload with template
          product.payload.channel.online = false
          console.log(product)
          // move-slide
          setTimeout(() => {
            this.dialogMyProductsAdd.busy = false
            this.dialogMyProductsAdd.slide++
            // found
            let friendlyName = (uri) => uri.split('-')[0].replace(/_/g, ' ').trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
            this.$q.notify({
              message: this.$t(this.getEcosystemLabel('BUSINESS_ADDED')),
              color: 'white',
              textColor: 'value',
              detail: friendlyName(product.uri),
              position: 'top',
              timeout: 2000
            })
            this.mutateProduct(product.id, product.payload, 'TEMPLATE', () => {
              // refresh
              this.getProducts(() => {})
              // verify
              setTimeout(() => {
                this.dialogMyProductsAdd.slide++
                this.dialogMyProductsAdd.done = true
              }, 1000)
            })
          }, 600)
        }
      })
    },
    createChannelCollection () {
      alert('Coming soon.')
    },
    createChannelBusiness () {
      this.dialogChannelCreateShow = false
      setTimeout(this.addProductInit, 100)
    },
    createChannel () {
      this.dialogChannelCreateShow = true
      // let actions = [{
      //   avatar: '/statics/_demo/dot.svg',
      //   label: 'Channel <p class="q-caption text-grey text-weight-semibold">Private collection of features</p>',
      //   value: 'custom'
      // }, {
      //   avatar: '/statics/_demo/list.bullet.below.rectangle.svg',
      //   label: 'Public Business Channel <p class="q-caption text-grey text-weight-semibold">For Google Businesses</p>',
      //   value: 'business'
      // }]
      // this.$q.actionSheet({ title: 'Create New Channel', actions }).then(action => {
      //   if (action.value === 'business') {
      //     this.addProductInit()
      //   } else {
      //     this.createChannelDialog()
      //   }
      // }).catch(() => {})
    },
    createChannelDialog (name, err) {
      // custom channel:
      // - (frontend) requires a title (name)
      // - (backend) specific API endpoint
      // - (backend) uri generated based on title and uuid
      // - (backend) no coordinate will be available
      // - (backend) public status will be set to false
      // - (backend) token (API access) generated automatically
      let minLength = 4
      this.$q.dialog({
        title: 'Channel Name',
        message: (err && err.error) ? err.error : `At least ${minLength} characters`,
        ok: this.$t('CREATE'),
        cancel: this.$t('CANCEL'),
        prompt: {
          model: name || 'Untitled',
          type: 'text'
        },
        preventClose: true
      }).then(data => {
        // update description
        let channelName = `${data}`.trim()
        if (channelName.length >= minLength) {
          alert('create channel')
          // call backend
        } else {
          setTimeout(() => this.createChannelDialog(channelName, { error: `Too short name (min ${minLength})` }), 1)
        }
      }).catch(() => {
        // ignore
      })
    },
    addProductInit () {
      this.addProductAllocate()
      // if (!this.device.pos) {
      //   this.$q.dialog({
      //     title: this.$t('LOCATION'),
      //     message: this.$t('LOCATION_MESSAGE'),
      //     ok: this.$t('YES'),
      //     cancel: this.$t('NO')
      //   }).then(() => {
      //     this.addProductLocalize()
      //   }).catch(() => {
      //     this.device.pos = null
      //     this.addProductAllocate(null)
      //   })
      // } else {
      //   this.addProductAllocate()
      // }
    },
    addProductLocalizeProcess (cb) {
      let err = () => {
        setTimeout(() => {
          this.$q.dialog({
            title: 'Warning',
            message: 'Could not use your location. Please check your locations settings.',
            ok: this.$t('OK'),
            preventClose: true
          })
        }, 400)
        cb(null)
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          if (pos) {
            this.device.pos = pos
            cb(pos)
          } else {
            setTimeout(err, 1000)
          }
        }, function error (msg) {
          err()
        }, {
          maximumAge: 10000,
          timeout: 5000,
          enableHighAccuracy: true
        })
      } else {
        err()
      }
    },
    addProductLocalize () {
      if (!this.device.pos) {
        this.$q.loading.show({
          spinner: QSpinnerRings,
          spinnerSize: 250
        })
        this.addProductLocalizeProcess(this.addProductAllocate)
      } else {
        this.addProductAllocate()
      }
    },
    addProductAllocate (pos) {
      this.$q.loading.hide()
      // check for pos
      // console.log(this.device.pos)
      if (!pos) {
        // console.log('[pos] trying again')
        // this.addProductLocalizeProcess()
      }
      // toggle dialog
      this.dialogMyProductsAdd.busy = true
      this.dialogMyProductsAdd.slide = 0
      setTimeout(() => {
        this.addProductDialogToggle()
        // process and move forward if we can
        axiosLIO.get('/ecosystem/capacity').then((res) => {
          if (res.data.data.products_creatable > 0) {
            // available space to add home
            setTimeout(() => {
              this.dialogMyProductsAdd.busy = false
              this.dialogMyProductsAdd.slide++
            }, 1000)
          } else {
            // err — need to add capacity
            setTimeout(() => {
              this.$q.dialog({
                title: this.$t(this.getEcosystemLabel('NO_SPACE_L')),
                message: this.$t(this.getEcosystemLabel('NO_SPACE_D')),
                ok: this.$t('YES'),
                cancel: this.$t('NO'),
                preventClose: true
              }).then(() => {
                this.addProductDialogToggle()
                setTimeout(() => {
                  console.log('OPEN PAYMENTS')
                }, 400)
              }).catch(() => {
                this.addProductDialogToggle()
              })
            }, 1000)
          }
        }).catch(err => {
          // bad err
          console.error(err)
        })
      }, 200)
    },
    addProductDialogToggle () {
      this.dialogMyProductsAdd.show = !this.dialogMyProductsAdd.show
    },
    processPropertySelection (card) {
      if (typeof card !== 'object') {
        let id = parseInt(card)
        card = this.$refs[`property-card-${id}`].$el || this.$refs[`property-card-${id}`][0].$el
      }
      // this.$router.push(`/${this.$store.state.app.io.ecosystem_id}/` + this.$guid.noHyphen(this.$guid.generate()))
      this.$router.push(`/${this.$store.state.app.io.ecosystem_id}/` + card.dataset.index)
    },
    processPropertyDeletion (card) {
      if (typeof card !== 'object') {
        let id = parseInt(card)
        card = this.$refs[`property-card-${id}`].$el || this.$refs[`property-card-${id}`][0].$el
      }
      // confirm
      this.$q.dialog({
        title: this.$t(this.getEcosystemLabel('DELETE.TITLE_QUERY')),
        message: this.$t(this.getEcosystemLabel('DELETE.MESSAGE_QUERY')),
        ok: this.$t('YES'),
        cancel: this.$t('NO')
      }).then(() => {
        // delete
        this.deleteHome(card.dataset.index)
      }).catch(() => {
        // ignore
      })
    },
    archiveHome (id, done) {
      axiosLIO.post('/product/archive', {
        id
      }).then((res) => {
        done(res)
      }).catch(err => {
        done(err)
      })
    },
    deleteHome (ix) {
      let product = this.$store.state.app.products.list[ix]
      this.dialogRemovingShow = true
      this.archiveHome(product.id, () => {
        setTimeout(() => {
          this.getProducts(() => {
            // hide deletion dialog
            this.dialogRemovingShow = false
          })
        }, 400)
      })
    },
    setCardIntent (obj, id, cb) {
      let card = this.$refs[`property-card-${id}`].$el || this.$refs[`property-card-${id}`][0].$el
      // ignore intent if card is disabled
      if (card.attributes.disabled) return
      // handle card
      if (obj.isFirst) {
        card.classList.add('intent')
      } else if (obj.isFinal) {
        if (card.classList.contains('intent')) {
          card.classList.remove('intent')
          if (Math.abs(obj.offset.x) < 50 && Math.abs(obj.offset.y) < 50) {
            // call handler
            setTimeout(function () {
              (cb)(card)
            }, 1)
          }
        }
      } else {
        if (card.classList.contains('intent')) {
          if (Math.abs(obj.offset.x) > 50 || Math.abs(obj.offset.y) > 50) {
            card.classList.remove('intent')
          }
        }
      }
    },
    toolbarShadowOnOverscrollTarget () {
      let modalTarget = null
      document.querySelectorAll('.modal').forEach((o, i) => {
        if (o.clientHeight !== 0) modalTarget = o
      })
      return modalTarget
    },
    toolbarShadowOnOverscrollClear (timeout = 10) {
      setTimeout(() => {
        try {
          let modalTarget = this.toolbarShadowOnOverscrollTarget()
          if (modalTarget) {
            modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
          }
        } catch (e) {
          console.log(e)
        }
      }, timeout)
    },
    toolbarShadowOnOverscroll (scroll) {
      let modalTarget = this.toolbarShadowOnOverscrollTarget()
      if (modalTarget) {
        if (scroll.direction === 'down' && scroll.position >= 1) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.add('toolbar-overscroll-shadow-show')
        } else if (scroll.direction === 'up' && scroll.position <= 10) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~variables'

.q-layout-page-container.q-layout-transition
  padding-top 66px !important

h3
  text-transform uppercase
img.hero
  width 50%
  max-width 400px
  opacity 0.2
p.legal
  padding 40px 0px 20px 0px

.q-card-media
  > img
    &.no-photos
      background-color $shadow
      background-repeat no-repeat
      background-position 50%
      background-size 40% 40%
      background-image url('~assets/icons/icon-cube.svg')
      background-blend-mode soft-light
      opacity 0.2
    &.no-photos-category-business
      background-image url('~assets/icons/icon-interior-bathroom.svg')
    &.no-photos-category-bedroom
      background-image url('~assets/icons/icon-interior-bedroom.svg')
    &.no-photos-category-livingroom
      background-image url('~assets/icons/icon-interior-living.svg')

.dialogMyHomesHomeEdit
  .layout-padding
    margin-top  -100px
    padding-top  122px
    // max-width 1040px
  .q-list
    .q-item-side
      .q-checkbox-icon
        font-size 26px
  .product
    .product-category
      margin-bottom -22px
    .q-card
      margin 4px
      &.ax-ix-area
        border 1px solid $primary !important
      .q-card-title
        img
          display block
          margin-right 8px
      .q-card-media
        > img
          height 300px
          &.no-photos
            background-color $shadow
            background-repeat no-repeat
            background-position 50%
            background-size 40% 40%
            background-image url('~assets/icons/icon-cube.svg')
            background-blend-mode soft-light
            opacity 0.2
          &.no-photos-category-business
            background-image url('~assets/icons/icon-interior-bathroom.svg')
          &.no-photos-category-bedroom
            background-image url('~assets/icons/icon-interior-bedroom.svg')
          &.no-photos-category-livingroom
            background-image url('~assets/icons/icon-interior-living.svg')
  .q-tabs
    width 80vw
    max-width 800px
    margin-top 45px
    margin-left auto
    margin-right auto
    .q-tabs-scroller
      padding-bottom 10px
    .q-tabs-head
      background-color none
      border-radius 1.6rem
      .q-tab
        font-family omnes-pro
        font-weight 600
        &:hover
          &::before
            border-radius 1.6rem
        &.active
          color $emphasis !important // increase contrast
          border-radius 1.6rem
        &:active
          background rgba(0,0,0,0.02)
          border-radius 1.6rem
    .q-tabs-pane
      margin-top -40px
  @media only screen and (max-device-width: 420px)
    .q-tabs
      width calc(100vw - 8px)
      left -8px // padding

// @media (max-width: 1199px) and (min-width: 992px)
@media (max-width: 1600px) and (min-width: 0px)
  .dialogMyHomesHomeEdit
    .layout-padding
      padding-left 0
      padding-right 0

body.touch
  .dialogMyHomesHomeEdit
    .layout-padding
      width 100%
      max-width 100%
      .scrolling-wrapper-flexbox
        position relative
        width 100vw
        left -32px
        margin-right -64px
figure
  &.stack
    width 100px
    padding 0
    position relative
    img
      max-width 100%
      position absolute
      top 0
      left 0
      transition all 0.3s
      border-radius .4rem
      border 1px solid alpha($shadow, .2)
      &:last-child
        position relative
    &.stack-spread.active, &.stack-spread:hover
      img
        &:first-child
          opacity .2
          transform translate(0px, 0px)
        &:nth-child(2)
          opacity .4
          transform translate(-8px, 0px) scale(1.05)
        &:nth-child(3)
          opacity .6
          transform translate(calc(-16px + -4px), 0px) scale(1.1)
        &:nth-child(4)
          opacity 1
          transform translate(calc(-24px + -16px), 0px) scale(1.2)
    @media only screen and (max-device-width: 420px)
      img
        display none
        // img
        //   display none
        //   &:first-child
        //     opacity 1
        //     transform translate(0px, 0px) scale(1)
        //   &:nth-child(2)
        //     opacity 1
        //     transform translate(0px, 0px) scale(1)
        //   &:nth-child(3)
        //     opacity 1
        //     transform translate(0px, 0px) scale(1)
        //     // animation figure-fader 4s ease 5s
        //   &:nth-child(4)
        //     opacity 1
        //     transform translate(0px, 0px) scale(1)
        //     animation figure-fader 4s ease 4s
        // @-webkit-keyframes figure-fader
        //   0%
        //     opacity 1
        //   100%
        //     opacity 0

.q-carousel-slide
  .q-subheading
    width 300px

.q-carousel-slide:after
  overflow hidden
  content ''
  position absolute
  border-radius 1.6rem
  background transparent

.q-carousel-slide.scanning:after {
  content: '';
  display: block;
  position: absolute;
  width: calc(100%);
  height: calc(100%);
  top: 0;
  left: 0
  z-index: 1;
  background:
    linear-gradient( 90deg,
      rgba(0, 0, 0,  .2),
      rgba(0, 0, 0,  .3) 30%,
      rgba(255,0,145,.4) 55%,
      rgba(255,0,145, 1) 56%,
      rgba(0, 0, 0,  .8) 57%,
      rgba(0, 0, 0,  .2)
    );
  background-size: 300% 100%;
  background-repeat: no-repeat;
  background-position: 84% center;
  -webkit-animation: scan 4s infinite ease;
  animation scan 4s infinite ease
}

@-webkit-keyframes scan
  60%
    background-position 25% center

.scrolling-wrapper-flexbox
  display flex
  flex-wrap nowrap
  overflow-x auto
  .q-card
    flex 0 0 auto
    margin-right 10px !important
    &:last-child
      margin-right 40px !important

.scrolling-wrapper-flexbox-row
  display flex
  flex-wrap wrap
  flex-direction row
  overflow-x auto
  .q-card
    flex 1 0 300px

body.touch
  .scrolling-wrapper-flexbox-row
    .q-card
      flex 1 1 140px

@media only screen and (max-device-width: $breakpoint-xs-max)
  body.touch
    .scrolling-wrapper-flexbox-row
      .q-card
        flex 1 1 120px

body.no-touch
  .q-card
    width 460px

body.no-touch
  .scrolling-wrapper-flexbox-row
    justify-content flex-start
    .q-card
      flex 0 1 240px
  .scrolling-wrapper-flexbox-row-wide
    .q-card
      flex 1 0 340px
      border 2px solid red

.scrolling-wrapper-flexbox, .scrolling-wrapper-flexbox-row
  padding 40px
  padding-left 2px
  margin-top -40px
  margin-bottom 0px
  width 100%
  -webkit-overflow-scrolling touch
  overflow-scrolling touch
  &::-webkit-scrollbar
    display none

#dialogItem, .dialog-item
  .q-modal-layout
    background-repeat no-repeat
    background-position 130% bottom
    background-color rgba(255, 255, 255, 0.96)
    background-blend-mode color
    background-size 50%
  .q-option-inner
    i
      font-size 160%
</style>

<style lang="stylus">
.autosuggest__results-container
  .autosuggest__results
    font-family omnes-pro
    position absolute
    border 1px solid #ccc
    border-radius 1.4rem
    width 100%
    background #fff
    background-image linear-gradient(180deg, rgba(255,255,255,0.922) 0%, rgba(255,255,255,0.749) 0%) !important
    box-shadow 0rem -0.375rem 2rem 0rem rgba(0,0,0,0.071) !important

.ais-input-autocomplete
  input
    width 100%
    padding-right 35px
    padding-left 16px
    line-height 40px
    height 40px
    border 1px solid #CCC
    border-radius 1.4rem
    outline none
    font-family omnes-pro
    font-weight 500
    appearance none
    -webkit-appearance none
    box-sizing border-box

#autosuggest
  &.empty
    .autosuggest__results-container
      display none

.autosuggest__results
  >ul
    list-style none
    padding 0
    li
      padding 0.4rem 1.4rem
  &-item--highlighted
    background-color $highlight
</style>